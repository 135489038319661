import { xorWith } from "lodash";
import moment from "moment"
import { COLUMN_DETAILS } from "../split/config";
var parseFormat = require('moment-parseformat')

export const NAME_VALIDATION_COLUMN = {
    name: 'NameMatch',
    email: 'EmailMatch',
    phone: 'PhoneMatch',
    taxId: 'TaxIdMatch',
    partial: 'PartialMatch',
    emptyFields: 'BlankFields',
    matchedName: function (name) {
        return name === COLUMN_DETAILS.columnData.name.name ? this.name :
            name === COLUMN_DETAILS.columnData.email.name ? this.email :
                name === COLUMN_DETAILS.columnData.phone.name ? this.phone :
                    this.taxId
    },
    getNames: function () {
        return [this.name, this.email, this.phone, this.taxId, this.partial, this.emptyFields]
    },
    getNamesForExcelJs: function () {
        return [{ header: this.name, key: this.name }, { header: this.email, key: this.email }, { header: this.phone, key: this.phone }, { header: this.taxId, key: this.taxId }, { header: this.partial, key: this.partial }, { header: this.emptyFields, key: this.emptyFields }]
    }
}
export const NAME_VALIDATION_INSTRUCTION = [
    {
        point: '1.Sort the Vendor name and check for the consistency in the Email id, phone number, Taxid.'
    },
    {
        point: '2.Sort the email id and check for the consistency in the vendor’s name, phone number, Taxid.'
    },
    {
        point: '3.Sort the phone number and check for the consistency in the vendor’s name, Email id and Taxid.'
    },
    {
        point: '4.Sort the Taxid and check for the consistency in the vendor’s name, Email id and Phone number.'
    },
    {
        point: ''
    },
    {
        point: ''
    },
    {
        point: 'Note: Generally, the vendor’s name will be sorted alphabetically during the name validation generation. This is for additional information. The inconsistencies error that is highlighted is only for additional reference, we need to check the entire instances.'
    },
]
export const keysToKeepinAmountValidationSheet = ['S.No', 'D.S.No', 'TYPE', 'FOLDER', 'File Name', 'amount', 'amount_1', 'memo', 'Additional Fields'];
export const keysToKeepinAmountValidationSheetFrColumn = ['S.No', 'D.S.No', 'TYPE', 'FOLDER', 'File Name', 'amount', 'amount', 'memo', 'Additional Fields'];
export const keysToKeepinNameValidationSheetAndColumn = ['S.No', 'D.S.No', 'File Name', 'name', 'email', 'phone', 'taxId', ...NAME_VALIDATION_COLUMN.getNames(), 'Checked'];
export const keysToKeepinNameValidationSheetAndColumnForExcelJs = [
    { key: 'S.No', header: 'S.No' },
    { key: 'D.S.No', header: 'D.S.No' },
    { key: 'File Name', header: 'File Name' },
    { key: 'name', header: 'name' },
    { key: 'email', header: 'email' },
    { key: 'phone', header: 'phone' },
    { key: 'taxId', header: 'taxId' },
    ...NAME_VALIDATION_COLUMN.getNamesForExcelJs(),
    { key: 'Checked', header: 'Checked' }
];

export const considerdJunk = ['~']
export const CONNECTING_WORDS = [
    'a',
    'about',
    'against',
    'along',
    'also',
    'although',
    'always',
    'am',
    'an',
    'and',
    'are',
    'as',
    'aside',
    'at',
    'be',
    'because',
    'being',
    'beside',
    'between',
    'but',
    'by',
    'either',
    'even',
    'even though',
    'for',
    'from',
    'had',
    'has',
    'have',
    'hence',
    'however',
    'if',
    'in',
    'into',
    'is',
    'it',
    'moreover',
    'neither',
    'nor',
    'not',
    'of',
    'on',
    'once',
    'or',
    'otherwise',
    'per',
    'rather',
    'since',
    'so',
    'than',
    'that',
    'the',
    'then',
    'there',
    'therefore',
    'these',
    'this',
    'though',
    'through',
    'thus',
    'to',
    'unless',
    'until',
    'versus',
    'ves',
    'vs',
    'via',
    'was',
    'were',
    'while',
    'whilst',
    'with',
    'without',
    'yet',
    'et',
    'al',
]
export const colorForNameValidation = (num, blank) => {
    if (blank) return 'FF5733'
    const cr = num == 1 ? 'FFFF00' :
        num == 2 ? '00FF00' :
            num == 3 ? 'FFA500' :
                num == 4 ? 'FFC0CB' :
                    num == 5 ? '6082B6' :
                        num == 6 ? 'ADD8E6' :
                            num == 7 ? 'DAA520' :
                                num == 8 ? 'AFE1AF' :
                                    num == 9 ? '00FFFF' :
                                        num == 10 ? '5F9EA0' : '808080'
    return cr
}
export const cmt = '2: File is not an invoice';
export const cmt2 = "4: Invoice data cannot be interpreted";
export const totalExceedCmt = "4: Invoice data cannot be interpreted";
export const CONSISTENCY_CHECK_FIELDS = ['name', 'phone', 'email', 'taxId']
export const COMMENTS = [
    '1: File cannot be processed',
    '1: File is password protected',
    cmt,
    "3: File isn't legible",
    cmt2,
    "5: Multiple Invoices",
    "5: Amount is not in GBP",
    "6: Unsupported file format",

]
export const COMMENTS_NOT_INVOICE = [
    '1: File cannot be processed',
    '1: File is password protected',
    cmt,
]
export const COLUMNS_LIST = [
    'S.No',
    'D.S.No',
    'TYPE',
    'FOLDER',
    'File Name',
    COLUMN_DETAILS.columnData.imagePath.name,
    'issuedDate',
    'dueDate',
    'amount',
    'invoiceNumber',
    'terms',
    'poNumber',
    'amount',
    'memo',
    'fileName',
    'name',
    'email',
    'phone',
    'taxId',
    'customerAccount',
    'Comment',
    'CoderName',
    'Remarks',
    'Additional Fields'
]
export const COLUMNS_LIST_FR_EXCEL = [
    'S.No',
    'D.S.No',
    'TYPE',
    'FOLDER',
    'File Name',
    COLUMN_DETAILS.columnData.imagePath.name,
    'issuedDate',
    'dueDate',
    'amount',
    'invoiceNumber',
    'terms',
    'poNumber',
    'amount_1',
    'memo',
    'fileName',
    'name',
    'email',
    'phone',
    'taxId',
    'customerAccount',
    'Comment',
    'CoderName',
    'Remarks',
    'Additional Fields'
]
export const SPLIT_TEMPLATE = [
    {
        TYPE: '09b',
        FOLDER: '20230503_0805_1',
        ['File Name']: '4bca298f-b150-4141-a710-b1fc3ebf5937.pdf'
    }
]

export const SPLIT_COLUMN = ['TYPE', 'FOLDER', 'File Name'];
export const FINAL_COLUMN = ['FOLDER', 'File Name'];
export const COMMENTS_EXCEL = [
    `"1: File cannot be processed,1: File is password protected,2: File is not an invoice,3: File isn\'t legible,4: Invoice data cannot be interpreted,5: Multiple Invoices,5: Amount is not in GBP,6: Unsupported file format"`
]
export const MEMO_LIST = ['Tax', 'Freight']
export const MEMO_LIST_EXCEL = ['"Tax, Freight"']
export const ADDITIONAL_FIELDS = ['amount', 'memo', 'quantity', 'Cost']
export const regExpConditions = {
    numbers: /^[0-9.]*$/,
    includePdfExtensionAtEnd: /^[^\\/:*?"<>|\r\n]+\.([Pp][Dd][Ff])$/,
    dateFormatUsa: /^(((\d{4}-((0[13578]-|1[02]-)(0[1-9]|[12]\d|3[01])|(0[13456789]-|1[012]-)(0[1-9]|[12]\d|30)|02-(0[1-9]|1\d|2[0-8])))|((([02468][048]|[13579][26])00|\d{2}([13579][26]|0[48]|[2468][048])))-02-29)){0,10}$/,
    // dateFormatUsa:/^\d{4}-(0[1-9]|1[0-2])-([0-2][1-9]|3[01])$/,
    AlphaNumeric: /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/,
    checkExtensions: /^[^\\/:*?"<>|\r\n]+\.([Aa][Cc][Cc]|[Bb][Aa][Tt]|[Cc][Dd][Xx]|[Dd][Oo][Cc]|[Dd][Oo][Cc][Xx]|[Dd][Oo][Tt]|[Ee][Xx][Ee]|[Hh][Tt][Mm]|[Hh][Tt][Mm][Ll]|[Jj][Aa][Rr]|[Jj][Ss]|[Jj][Ss][Oo][Nn]|[Ll][Nn][Kk]|[Mm][Pp][3]|[Mm][Pp]4|[Oo][Dd][Ss]|[Oo][Tt][Hh]|[Pp][Dd][Bb]|[Pp][Dd][Ff]|[Pp][Nn][Gg]|[Pp][Pp][Tt]|[Pp][Ss][Dd]|[Pp][Yy]|[Rr][Aa][Rr]|[Rr][Bb]|[Rr][Tt][Ff]|[Ss][Vv][Gg]|[Tt][Xx][Tt]|[Xx][Ll][Ss]|[Xx][Ll][Ss][Xx]|[Xx][Mm][Ll]|[Zz][Ii][Pp])$/,
    titleCase: /^(?:[A-Z][a-z]*)(?:\s+[A-Z][a-z]*)*$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    phone: /^\+?[0-9()\-.\/]*$/,
    numeric: /^[0-9]+$/,
    twoWhiteSpaceAndPipeOperator: /\|/,
    space: /\s/,
    isNotAlphaOnly: /.*[^a-zA-Z].*/,
    containNumber: /\d/,
    junkChar: /[^\x00-\x7F]/,
    isFirstCharacterAlpha: /^[a-zA-Z]/

}
const ERR_MSG = {
    ['issuDate']: 'Issue Date format mismatch in ',
    ['amount']: '8.1 - "Amount" - Field has invalid number format. Please check',
    ['amount_1']: '12.1 - "Amount" - Field has invalid number format. Please check',
    ['invoiceNumber']: 'Invoice Number should be in Alphanumeric',
    ['memo']: '13.1 - "Memo" should contain only the value "Tax" or "Freight". Please check',
    ['File Name']: '5.1 - "Pdf File Name" (E-Colm) and "File Name" (N-Colm) does not match. Please check.',
    ['name']: 'Name should be in Title case',
    ['email']: '16.1 - Email format mismatch(Eg - abc@gmail.com)',
    ['phone']: '17.1 - The character "(", ")", "-", ".", "/" is only allowed in phone field. Please check.',
    ['customerAccount']: 'customerAccount error',
    ['Comment']: `20.1 - Comment should be in one of this following List : ${COMMENTS.join(',')}`,
    ['memo || amount']: 'if one field have value then another shouldn`t be empty',
    ['amount && additional field total']: 'Invoice amount total mismatch',
    ['quantity']: 'Quantity field error',
    ['basic']: "All Fields shouldn't be contain two c whitespace and |",
    ['Additional Field quantity']: 'Additional Field quantity have format error',
    ['amount && memo field']: 'Additional fields amount and memo values should be same',
}
const ERROR_MSG = {
    additionalFieldAmountValidationTotal: 'Additional Field amount Total unmatched',
    dateFormat: '6.2 - Date format should be in YYYY-MM-DD, it is updated in "Data" sheet. Please check.',
    invoiceNumber: 'Invoice Number should be in Alphanumeric only',
    poNumber: '11.1 - "PO Number" must contain numeric values. so keyed value is removed. Please check.',
    dueDateValueAddedFromTerms: '7.2 - DueDate Value has been updated based on the terms, Kindly check once manually',
    inVoiceMissing: "Invoice field shouldn't be blank",
    invoiceMissingButCustomerAccount: "9.1 - Account number is present, but Invoice number is blank, please check and update the account no. in the Invoice number field.",
    additionalFieldAmtInvalid: '23.2 - Additional Field - "amount" has invalid number format.Please Check',
    AdditionalFieldAmountAndMemoCount: '23.3 - Additional Field - Column Count mismatch for Amount and Memo column. Please check.',
    additionalFieldQuantity: '24.1 - Additional Field - "quantity" has invalid number format.Please Check',
    fortwoWhitespaceAndPipeOperator: '27.2 - Pipe operator (|) replaced by space.',
    additionalFieldCostInvalid: '25.1 - Additional Field - Cost has invalid number format.Please Check',
    amountDecimal: '28.1 - Amount field have more than two digits after decimal',
    generalError: {
        invoiceFirstThreeLetter: 'G1 - Invoice No maybe wrong',
        invDuplicate: `G2- ${COLUMN_DETAILS.columnData.invoiceNumber.name} have Duplicates. Check ${COLUMN_DETAILS.columnData.DSNo.name} column `,
        poDuplicate: `G3- ${COLUMN_DETAILS.columnData.poNumber.name} have Duplicates. Check ${COLUMN_DETAILS.columnData.DSNo.name} column `,
        customerAccDuplicate: `G4- ${COLUMN_DETAILS.columnData.customerAccount.name} have Duplicates. Check ${COLUMN_DETAILS.columnData.DSNo.name} column `,
        additionalFieldEmpty: `G5- ${COLUMN_DETAILS.columnData.amount.name} field keyed value is 0 and Additional Fields values are empty. `,
        taxIdLength: `G6- ${COLUMN_DETAILS.columnData.taxId.name} field length is less than 9 `,
        phoneNoBracketMismatch: `G7 - ${COLUMN_DETAILS.columnData.phone.name} field have missing brackets`

    }
}
const constructErrMsg = (name, dsno, sno, value, type, item) => {
    return {
        ['S.No']: sno,
        ['D.S.No']: dsno,
        FileName: item['File Name'],
        imagePath: item[COLUMN_DETAILS.columnData.imagePath.name],
        FieldName: name,
        keyedValue: value,
        updatedValue: 'NA',
        Msg: ERR_MSG[`${name}`],
        Type: type,
        status: 'NA',
        checked: 'N'
    }
}
const constructErrMsg2 = (data) => {
    const { name, dsno, sno, value, type, changedValue, status, msgKey, msg, item = {} } = data
    return {
        ['S.No']: sno,
        ['D.S.No']: dsno,
        FileName: item['File Name'],
        imagePath: item[COLUMN_DETAILS.columnData.imagePath.name],
        FieldName: name,
        keyedValue: value,
        updatedValue: changedValue,
        Msg: msg || msgKey || ERR_MSG[`${name}`],
        Type: type,
        status: status || 'NA',
        checked: 'N'

    }
}
export const columnCheck = async (data, showErrorMsg, setLoad, all) => {
    try {
        if (data?.length && !all) {
            const keys = Object.keys(data[0]);
            if (keys[0] = 'TYPE' && keys[1] === "FOLDER" && keys[2] === 'File Name') return true
            else {
                setLoad(false);
                showErrorMsg('Please Provide the Valid Template, Download the Template Above For Your Reference')
            }
        }
        if (all && data?.length) {
            const keys = Object.keys(data[0]).filter(i => !i.includes('__EMPTY'));
            if (JSON.stringify(keys) === JSON.stringify(COLUMNS_LIST_FR_EXCEL)) return true;
            else {
                showErrorMsg('Please Provide the Valid Template, Download the Template Above For Your Reference')
                setLoad(false);
            }
        } else {
            setLoad(false);
            return false;
        }
    } catch (error) {
        setLoad(false);
        showErrorMsg('Please Provide the Valid Template and Download the Template Above For Your Reference')
    }

}

function convertFractionsToText(str) {
    const fractionMap = {
        "½": " 1/2",
        "¼": " 1/4",
        "¾": " 3/4",
        "⅓": " 1/3",
        "⅔": " 2/3",
        "⅛": " 1/8",
        "⅜": " 3/8",
        "⅝": " 5/8",
        "⅞": " 7/8",
    };

    // Replace fraction representations with their actual text form
    for (const fraction in fractionMap) {
        if (str?.includes(fraction)) {
            str = str.replace(new RegExp(fraction, "g"), fractionMap[fraction]);
        }
    }

    return str;
}

function replaceQuotationMarks(str) {
    // Replace left double quotation mark (opening quote)
    str = str?.replace(/[\u201C\u201F\u201D]/g, '"');

    // Replace right double quotation mark (closing quote)
    str = str?.replace(/[\u2018\u201B\u2019]/g, "'");

    return str;
}

const allowAllMsg = false;
export const qcValidation = (data) => {
    console.log(data, 'xdftyguvhiubjhoubhjihubhj');
    const error = [];
    const warning = [];
    const commonNames = [];
    const amountMismatchArr = [];
    // const modifiedData = [];
    const checkedNameList = [];

    let matchingNamesData = {};
    let errorNames = [];
    let errorMessages = [];
    let finalMatchingNamesData1 = [];
    class GeneralError {
        constructor() {
            this.invDuplicate = [];
            this.poDuplicate = [];
            this.customerAccDuplicate = [];

            this.invDuplicateList = {};
            this.poDuplicateList = {};
            this.customerAccDuplicateList = {};

            this.invFirstThreeLetterCheckErr = [];
            this.additionalFieldEmptyErr = [];
            this.taxIdLengthErr = [];
            this.phoneNoValidationErr = [];
        }
        generalErrorCheck(item) {
            // this.invoiceNoFirstThreeLetterCheck(item);
            // this.findDuplicates(item);
            // if (item['Additional Fields'] === "amount") this.checkAdditionalField(item)
            // this.taxIdLengthCheck(item);
            // this.checkAdditionalField(item)
        }
        invoiceNoFirstThreeLetterCheck(item) {
            const invName = item[COLUMN_DETAILS.columnData.invoiceNumber.name];
            if (invName?.length > 3) {
                const threeLetter = invName.substring(0, 3);
                if (threeLetter[0]?.toLowerCase() === 'i' || threeLetter[1]?.toLowerCase() === 'n' || threeLetter[2]?.toLowerCase() === 'v') {
                    if (threeLetter?.toLowerCase() !== 'inv') {
                        this.invFirstThreeLetterCheckErr.push(
                            constructErrMsg2({
                                name: COLUMN_DETAILS.columnData.invoiceNumber.name,
                                dsno: item['D.S.No'],
                                sno: item['S.No'],
                                changedValue: 'NA',
                                value: invName,
                                type: 'Warning',
                                msg: ERROR_MSG.generalError.invoiceFirstThreeLetter,
                                status: 'NA',
                                item
                            })
                        )
                    }
                }
            }
        }
        findDuplicates(item) {
            const invoiceNo = item[COLUMN_DETAILS.columnData.invoiceNumber.name];
            const poNo = item[COLUMN_DETAILS.columnData.poNumber.name];
            const customerAccNo = item[COLUMN_DETAILS.columnData.customerAccount.name];
            if (invoiceNo) {
                this.invDuplicateList[invoiceNo] ?
                    this.invDuplicateList[invoiceNo].push(item[COLUMN_DETAILS.columnData.DSNo.name]) :
                    this.invDuplicateList[invoiceNo] = [item[COLUMN_DETAILS.columnData.DSNo.name]]
            }
            if (poNo) {
                this.poDuplicateList[poNo] ?
                    this.poDuplicateList[poNo].push(item[COLUMN_DETAILS.columnData.DSNo.name]) :
                    this.poDuplicateList[poNo] = [item[COLUMN_DETAILS.columnData.DSNo.name]]
            }
            if (customerAccNo) {
                this.customerAccDuplicateList[customerAccNo] ?
                    this.customerAccDuplicateList[customerAccNo].push(item[COLUMN_DETAILS.columnData.DSNo.name]) :
                    this.customerAccDuplicateList[customerAccNo] = [item[COLUMN_DETAILS.columnData.DSNo.name]]
            }

        }
        checkAdditionalField(item) {
            const invoiceAmt = Number(item['amount']);
            if (invoiceAmt == 0) {
                const amountList = Object.keys(item);
                const getAdditionalFields = amountList?.filter(i => i.includes('__EMPTY') && item[i]?.toString().replace(/[\s]/g, ''));
                if (getAdditionalFields?.length === 0) this.additionalFieldEmptyErr.push(
                    constructErrMsg2({
                        name: 'Aditional Field -  amount',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        changedValue: 'NA',
                        value: '',
                        type: 'Warning',
                        msg: ERROR_MSG.generalError.additionalFieldEmpty,
                        status: 'NA',
                        item
                    })
                )
            }

        }
        taxIdLengthCheck(item) {
            const taxId = item[COLUMN_DETAILS.columnData.taxId.name]
            if (taxId && taxId?.length <= 8) this.taxIdLengthErr.push(
                constructErrMsg2({
                    name: COLUMN_DETAILS.columnData.taxId.name,
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    changedValue: 'NA',
                    value: taxId,
                    type: 'Warning',
                    msg: ERROR_MSG.generalError.taxIdLength,
                    status: 'NA',
                    item
                })
            )
        }
        checkPhoneNoBrackets(item) {
            const phoneNo = item[COLUMN_DETAILS.columnData.phone.name];
            if (phoneNo && !this.#hasEqualOpenCloseBrackets(phoneNo)) {


                this.phoneNoValidationErr.push(
                    constructErrMsg2({
                        name: COLUMN_DETAILS.columnData.phone.name,
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        changedValue: 'NA',
                        value: phoneNo,
                        type: 'Warning',
                        msg: ERROR_MSG.generalError.phoneNoBracketMismatch,
                        status: 'NA',
                        item
                    })
                )
            }
        }
        getErrList() {
            const invDuplicateErr = Object.keys(this.invDuplicateList)?.map(name => {
                if (this.invDuplicateList[name]?.length > 1) return constructErrMsg2({
                    name: COLUMN_DETAILS.columnData.invoiceNumber.name,
                    dsno: this.invDuplicateList[name]?.filter(Boolean)?.toString(),
                    sno: '',
                    changedValue: 'NA',
                    value: name,
                    type: 'Warning',
                    msg: ERROR_MSG.generalError.invDuplicate,
                    status: 'NA',
                })
                else return null
            }).filter(Boolean)
            const poDuplicate = Object.keys(this.poDuplicateList)?.map(name => {
                if (this.poDuplicateList[name]?.length > 1) return constructErrMsg2({
                    name: COLUMN_DETAILS.columnData.poNumber.name,
                    dsno: this.poDuplicateList[name]?.filter(Boolean)?.toString(),
                    sno: '',
                    changedValue: 'NA',
                    value: name,
                    type: 'Warning',
                    msg: ERROR_MSG.generalError.poDuplicate,
                    status: 'NA',
                })
                else return null
            }).filter(Boolean)
            const customerDuplicate = Object.keys(this.customerAccDuplicateList)?.map(name => {
                if (this.customerAccDuplicateList[name]?.length > 1) return constructErrMsg2({
                    name: COLUMN_DETAILS.columnData.customerAccount.name,
                    dsno: this.customerAccDuplicateList[name]?.filter(Boolean)?.toString(),
                    sno: '',
                    changedValue: 'NA',
                    value: name,
                    type: 'Warning',
                    msg: ERROR_MSG.generalError.customerAccDuplicate,
                    status: 'NA',
                })
                else return null
            }).filter(Boolean)

            return [
                ...this.invFirstThreeLetterCheckErr,
                ...invDuplicateErr,
                ...poDuplicate,
                ...customerDuplicate,
                ...this.additionalFieldEmptyErr,
                ...this.taxIdLengthErr
            ]
        }

        #hasEqualOpenCloseBrackets(str) {
            let openBracketCount = 0;
            let closeBracketCount = 0;

            for (let char of str) {
                if (char === '(') {
                    openBracketCount++;
                } else if (char === ')') {
                    closeBracketCount++;
                }
            }

            return openBracketCount === closeBracketCount;
        }
    }
    let generalError = new GeneralError()
    const addWarningMsg = (msg) => {
        warning.push(msg)
    };
    const addAmountMismatch = (data) => {
        amountMismatchArr.push(data);
    }
    const nameSlice = (name) => {
        let n = name?.substring(0, 3)?.toLowerCase()
        return n;
    }
    let modifiedData = data.map((item, index) => {
        const current = { ...item };
        console.log(item, 'fgcvhbn');


        for (let x of Object.keys(item)) {
            let t;
            // remove Enter mark
            const removedEnter = current[x]?.toString().replace(/[\r\n]+/g, '');
            if (removedEnter && current[x] && removedEnter !== current[x]?.toString() && (current['File Name'] || x.includes('__EMPTY')) && !['S.No', 'D.S.No', 'TYPE', 'FOLDER'].includes(x)) {
                console.log(removedEnter, current[x], 'szdxfcgvbhhkjg');
                current[x] = removedEnter;
                const name = x.includes('__EMPTY') ?
                    `Additional Fields - ${current['Additional Fields']}`
                    : x
                allowAllMsg && addWarningMsg(constructErrMsg2({
                    name: name,
                    dsno: current['D.S.No'],
                    sno: current['S.No'],
                    value: current[x],
                    type: 'Warning',
                    msg: '27.4 - Enter Mark removed in "data" sheet. Please check',
                    item: current,
                    changedValue: removedEnter,
                    status: 'Changed'
                }))
            }
            //  remove all spaces before and after :;
            if (x !== 'Comment') {
                const removedSpaces = current[x]?.toString().replace(/\s*([:;])/g, '$1');
                if (removedSpaces !== current[x]?.toString() && current[x]) current[x] = removedSpaces;
            }

            // data enterd in wrong row(without filename)
            if (!current['File Name'] && !x.includes('__EMPTY') && current[x] && !['S.No', 'D.S.No', 'TYPE', 'FOLDER', 'Additional Fields', 'CoderName', 'memo', 'amount_1'].includes(x)) {
                addWarningMsg(constructErrMsg2({
                    name: x,
                    dsno: current['D.S.No'],
                    sno: current['S.No'],
                    value: current[x],
                    type: 'Warning',
                    msg: '27.5 - Wrong values keyed in blank rows. Please check.',
                    item: current,
                }))
            }
            // if (typeof current[x] === 'string') {
            // remove space in start and end
            t = current[x]?.toString().trim();
            if (regExpConditions.twoWhiteSpaceAndPipeOperator.test(t)) {
                const name = x.includes('__EMPTY') ?
                    `Additional Fields - ${item['Additional Fields']}`
                    : x;
                const repVal = t?.replace(/\|/g, ' ')
                allowAllMsg && addWarningMsg(constructErrMsg2({
                    name: name,
                    dsno: current['D.S.No'],
                    sno: current['S.No'],
                    value: current[x],
                    type: 'Warning',
                    msgKey: ERROR_MSG.fortwoWhitespaceAndPipeOperator,
                    item: current,
                    changedValue: repVal,
                    status: 'Changed'
                }))
                current[x] = repVal
            }
            // change fractions
            if (t) {
                const convertedText = convertFractionsToText(t);
                if (convertedText !== t) {
                    allowAllMsg && addWarningMsg(constructErrMsg2({
                        name: x,
                        dsno: current['D.S.No'],
                        sno: current['S.No'],
                        value: t,
                        changedValue: convertedText?.trim(),
                        type: 'Warning',
                        msg: '27.8 - Changed fractions to text form in data sheet. Kindly check',
                        item: current,
                        status: 'Changed'
                    }))
                    current[x] = convertedText?.trim();
                    t = convertedText?.trim();
                }
            }
            // Replace left double quotation mark
            if (t) {
                const convertedText = replaceQuotationMarks(t);
                const name = x.includes('__EMPTY') ?
                    `Additional Fields - ${item['Additional Fields']}`
                    : x;
                if (convertedText !== t) {
                    allowAllMsg && addWarningMsg(constructErrMsg2({
                        name: name,
                        dsno: current['D.S.No'],
                        sno: current['S.No'],
                        value: t,
                        changedValue: convertedText?.trim(),
                        type: 'Warning',
                        msg: '27.6 - Changed left/right double/single quotation marks to normal quotation marks in data sheet. Kindly check',
                        item: current,
                        status: 'Changed'
                    }))
                    current[x] = convertedText?.trim();
                    t = convertedText?.trim();
                }

            }
            // remove ellipsis(...)
            const rmElips = t?.replace(/\.{3}/g, '');
            if (rmElips !== t && t) {
                const name = x.includes('__EMPTY') ?
                    `Additional Fields - ${item['Additional Fields']}`
                    : x
                allowAllMsg && addWarningMsg(constructErrMsg2({
                    name: name,
                    dsno: current['D.S.No'],
                    sno: current['S.No'],
                    value: t,
                    changedValue: rmElips,
                    type: 'Warning',
                    msg: '27.9 - Removed ellipsis.Kindly check',
                    item: current,
                    status: 'Changed'
                }))
                current[x] = rmElips;
            }
            // remove more than one whitespace
            const removedSpace = t?.replace(/\s+/g, ' ');
            if ((t !== removedSpace && !x.includes('amount') && !(x.includes('__EMPTY') && (current['Additional Fields'] === 'amount' || current['Additional Fields'] === "Cost" || current['Additional Fields'] === "quantity")))) {
                if (x !== 'email') {
                    console.log(x === 'email', x, typeof x, '3w4ert67gyvh8uhbuhi');
                    current[x] = removedSpace;
                    const name = x.includes('__EMPTY') ?
                        `Additional Fields - ${item['Additional Fields']}`
                        : x
                    allowAllMsg && addWarningMsg(constructErrMsg2({
                        name: name,
                        dsno: current['D.S.No'],
                        sno: current['S.No'],
                        value: t,
                        changedValue: removedSpace,
                        type: 'Warning',
                        msg: '27.1 - Removed more than one consecutive space to a single space. Kindly check',
                        item: current,
                        status: 'Changed'
                    }))
                }

            }
            // find junk characters
            // const normalizedStr = t?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            // if (t !== normalizedStr) {
            if (regExpConditions.junkChar.test(t) || considerdJunk.some(char => t?.includes(char))) {
                // current[x] = normalizedStr;
                const name = x.includes('__EMPTY') ?
                    `Additional Fields - ${item['Additional Fields']}`
                    : x
                addWarningMsg(constructErrMsg2({
                    name: name,
                    dsno: current['D.S.No'],
                    sno: current['S.No'],
                    value: t,
                    type: 'Warning',
                    msg: '27.3 - junk character present - please remove',
                    item: current,
                }))
            }
            // }

            // if (x === "name" && current['File Name'] && current[x]) {
            //     try {
            //         const isFind = commonNames?.find(i => i?.name === current[x]);
            //         // show blank field list in namevalidation sheet
            //         if (!current['phone'] || !current['email'] || !current['taxId']) {
            //             // const t = [];
            //             // if (!current['phone']?.toString().replace(/\s/g, "")) t.push('phone');
            //             // if (!current['email']?.toString().replace(/\s/g, "")) t.push('email');
            //             // if (!current['taxId']) t.push('taxId');
            //             errorNames.push(current[x]?.substring(0, 3)?.toLowerCase());
            //             // errorMessages.push({ dsNo: current['D.S.No'], msg: `${t?.join(',')} have blank field`, field: [] })
            //         }
            //         if (!isFind) {
            //             commonNames.push({
            //                 name: current['name'],
            //                 taxId: current['taxId'],
            //                 email: current['email']?.replace(/\s/g, ""),
            //                 phone: current['phone']?.replace(/\s/g, ""),
            //                 dsNo: current['D.S.No'],
            //                 sNo: current['S.No']

            //             })
            //         }
            //         else {
            //             const t = (isFind?.phone === current['phone']?.toString().replace(/\s/g, "") && isFind?.email === current['email']?.toString().replace(/\s/g, "") && isFind?.taxId === current['taxId'])
            //             if (isFind?.phone === current['phone']?.toString().replace(/\s/g, "") && isFind?.email === current['email']?.toString().replace(/\s/g, "") && isFind?.taxId === current['taxId']) console.log('g');
            //             else {
            //                 const t = [];
            //                 if (isFind?.phone !== current['phone']?.toString().replace(/\s/g, "")) t.push('phone');
            //                 if (isFind?.email !== current['email']?.toString().replace(/\s/g, "")) t.push('email');
            //                 if (isFind?.taxId !== current['taxId']) t.push('taxId');
            //                 const errMsg = `15.3 - "Vendor Name" are same in "D.S.No-${isFind?.dsNo}" & "D.S.No-${current['D.S.No']}". But "${t.join(',')}" does not match.`
            //                 errorNames.push(current[x]?.substring(0, 3)?.toLowerCase());
            //                 errorMessages.push({ dsNo: current['D.S.No'], msg: errMsg, field: t })
            //                 errorMessages.push({ dsNo: isFind.dsNo, msg: '', field: t, higlight: true })
            //                 addWarningMsg(constructErrMsg2({
            //                     name: 'name',
            //                     dsno: current['D.S.No'],
            //                     sno: current['S.No'],
            //                     value: '',
            //                     type: 'Error',
            //                     // msg: `D.S.No-${isFind?.dsNo} Vendor Name & D.S.No-${current['D.S.No']} Vendor Name is same but mismatch found in ${t.join(',')} fields`,
            //                     msg: errMsg,
            //                     value: current['name'],
            //                     item: current,
            //                 }))
            //             }
            //         }
            //     } catch (error) {
            //         console.log(error, 'errorerrorerrorerror1122');
            //     }

            // }


            if ((x.includes('__EMPTY') && item['Additional Fields'] === 'memo') || x === 'name' || x === 'terms') {
                let value = current[x]?.toString();
                if ([".", "-", "/", "\\"].includes(value?.at(-1))) {
                    const name = x.includes('__EMPTY') ?
                        `Additional Fields - ${current['Additional Fields']}`
                        : x
                    // let xValue = item[x]?.replace(/\.$/, '');
                    let xValue = current[x]?.replace(/[./\\-]+$/, "")?.trim();
                    allowAllMsg && addWarningMsg(constructErrMsg2({
                        name: name,
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: value,
                        type: 'Warning',
                        changedValue: xValue,
                        msg: `15.2 - ". - / \\"should not be present in end of the "${name}" field - Removed in the "Data" file. Please check`,
                        item
                    }))
                    current[x] = xValue;
                    value = xValue;
                }
                if (value) nameValidation(current, addWarningMsg, value, x)
            }

            if (x.includes('__EMPTY') && (item['Additional Fields'] === 'Cost' || item['Additional Fields'] === 'quantity') && current[x]) {
                const name = x.includes('__EMPTY') ? `Additional Field - ${item['Additional Fields']}` : x
                console.log(item[x], 'item[x]');
                if (current[x]) {
                    let rmSpace1 = current[x]?.toString()?.replace(/[\s]/g, '');
                    if (item['Additional Fields'] === 'Cost') {
                        const rmColon = rmSpace1.replace(/^:+|:+$/g, '');
                        const rmHypenOnEnd = rmColon.replace(/-+$/, '');
                        const rmColon2 = rmHypenOnEnd.replace(/^:+|:+$/g, '');
                        // remove more then one hypen only
                        const rm = rmColon2.replace(/^-+/, '-');
                        // const rm = rmSpace1.replace(/^[\-:]+|[\-:]+$/g, '');
                        if (rmSpace1 !== rm) {
                            addWarningMsg(constructErrMsg2({
                                name: name,
                                dsno: item['D.S.No'],
                                sno: item['S.No'],
                                value: current[x],
                                type: 'Error',
                                changedValue: rm,
                                msg: `25.4 -  Additional Field - ${item['Additional Fields']} removed hypen("-"/ ":").Kindly check`,
                                item,
                                status: 'Changed'
                            }))
                            current[x] = rm

                        }

                    }
                    let rmSpace = current[x]?.toString().replace(/[\s]/g, '');
                    // rmvHypen(rmSpace);
                    if ((Number(rmSpace) <= 0 || Number(rmSpace) > 0)) {
                        // amountBasicValidation(item, x, addWarningMsg, name, true)
                        if (rmSpace !== current[x]?.toString()) {
                            addWarningMsg(constructErrMsg2({
                                name: name,
                                dsno: item['D.S.No'],
                                sno: item['S.No'],
                                value: current[x],
                                type: 'Warning',
                                changedValue: (rmSpace),
                                msg: `24.1 - Space/Comma Removed from "${name}" field and updated in "Data" Sheet. Please check`,
                                item,
                                status: 'Changed'
                            }))

                            current[x] = (rmSpace)
                        }
                    }
                    else addWarningMsg(constructErrMsg2({
                        name: name,
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: current[x],
                        type: 'Error',
                        msg: `25.1 - Aditional Field - ${item['Additional Fields']} have invalid number format`,
                        item

                    }))
                }
            }
            if (x === 'Remarks' || x === 'Comment') {
                if (current[x]?.toLowerCase().includes('doubt')) addWarningMsg(constructErrMsg2({
                    name: x,
                    dsno: current['D.S.No'],
                    sno: current['S.No'],
                    value: current[x],
                    type: 'Error',
                    msg: `22.1 - The word "Doubt" should not be present and it should be clarified/updated before delivery. Please check`,
                    item: current

                }))
            }
            // show error if no column have values and comment also not mentioned as required 
            if (current['File Name'] && x === "File Name") {
                const columnExclude = ['S.No', 'D.S.No', 'TYPE', 'FOLDER', 'File Name', 'fileName', 'CoderName', 'Remarks', 'Comment', 'Additional Fields', 'Image Path']
                const reqKeys = Object.keys(current)?.filter(key => !columnExclude.includes(key));
                const noValues = reqKeys.every(key => !current[key]);
                if (noValues && !COMMENTS_NOT_INVOICE.includes(item['Comment'])) {
                    addWarningMsg(constructErrMsg2({
                        name: 'Comment',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: item['Comment'],
                        type: 'Warning',
                        msg: `20.3 - All fields shouldn't be blank(atleast one field should have value) or enter the correct comment.`,
                        item,
                    }))
                } else if (COMMENTS_NOT_INVOICE.includes(item['Comment']) && !noValues) {
                    addWarningMsg(constructErrMsg2({
                        name: 'Comment',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: item['Comment'],
                        type: 'Warning',
                        msg: `20.2 - All fields should be empty for the comment keyed.`,
                        item,
                    }))
                }
            }
            current[x] = current[x]?.toString().trim();
        }



        dateValidations(current, addWarningMsg, () => writeDates(current, current, addWarningMsg));




        amountAndQuantityValidation(current, current, addWarningMsg)
        customerAccValidation(current, current, addWarningMsg)

        //    customerAccValidation(item['customerAccount'], item['invoiceNumber'])
        // if (!amountValidation(item['amount_1'])) warning.push(constructErrMsg('amount_1', item['D.S.No'], item['S.No'], item['amount_1'], 'Warning'));

        if (!memoValidation(current['memo'], current)) warning.push(constructErrMsg('memo', current['D.S.No'], item['S.No'], item['memo'], 'Warning', current));

        invoiceAndPoNumberCheck(current, current, addWarningMsg);

        if (!fileNameValidation(current['File Name'], current['fileName'])) warning.push(constructErrMsg('File Name', current['D.S.No'], current['S.No'], current['File Name'], 'Warning', current));


        if (!emailValidation(current['email'], current, 'email')) warning.push(constructErrMsg('email', current['D.S.No'], current['S.No'], current['email'], 'Warning', current));

        if (!phoneNoValidation(current, addWarningMsg)) warning.push(constructErrMsg('phone', current['D.S.No'], current['S.No'], current['phone'], 'Warning', current));



        if (!commentValidation(current['Comment'])) warning.push(constructErrMsg('Comment', current['D.S.No'], current['S.No'], current['Comment'], 'Warning', current));

        const tarr = nameSlice(current['name'])
        if (current[COLUMN_DETAILS.columnData.DSNo.name]) {
            if (Array.isArray(matchingNamesData[tarr])) {
                matchingNamesData[tarr] = [...matchingNamesData[tarr], { ...current }];
            }
            else matchingNamesData[tarr] = [{ ...current }]
        }

        return current
    })
    let changesCount = {
        name: {},
        phone: {},
        email: {},
        taxId: {}
    }


    // name string similarity check
    modifiedData?.map((item, index) => {
        let current23 = { ...item };
        checkTotalAmount(current23, current23, addWarningMsg, modifiedData, index, addAmountMismatch);
        if (current23['name']) {
            const t = current23['name'];
            if (t?.length && !checkedNameList.includes(t)) {
                // checkedNameList.push(t);
                const tSplice = nameSlice(t)
                const unmatchedName = modifiedData?.filter((i, position) => {
                    const ti = nameSlice(i['name']);
                    // empty fields validation
                    if (i[COLUMN_DETAILS.columnData.DSNo.name]) {
                        let emptyFields = [];
                        CONSISTENCY_CHECK_FIELDS.forEach(name => {
                            if (!i[name]) emptyFields.push(name);
                        });
                        if ((emptyFields.length && emptyFields.length < 4) || (emptyFields.length === 4 && i['Comment'] !== cmt)) {
                            const fndIndex = [...matchingNamesData[ti]].findIndex(j => j[COLUMN_DETAILS.columnData.SNo.name] === i[COLUMN_DETAILS.columnData.SNo.name])
                            matchingNamesData[ti][fndIndex][NAME_VALIDATION_COLUMN.emptyFields] = emptyFields.join(',')
                        }
                    }

                    if (position > index && i['name']) {
                        const anyMatchFound = CONSISTENCY_CHECK_FIELDS.find(fieldName => (current23[fieldName] === i[fieldName] && (current23[fieldName] || i[fieldName])));
                        if (anyMatchFound) {
                            const everyMatch = CONSISTENCY_CHECK_FIELDS.every(fieldName => current23[fieldName] === i[fieldName]);

                            if (!everyMatch) {
                                let match = [];
                                let unMatched = []
                                CONSISTENCY_CHECK_FIELDS.forEach(j => {
                                    if ((current23[j] === i[j] && (current23[j] || i[j]))) match.push(j);
                                    else if ((current23[j] || i[j])) unMatched.push(j)
                                })
                                // push to nameValidation sheet
                                errorNames.push(tSplice?.toLowerCase());
                                errorNames.push(ti?.toLowerCase());
                                const constructMsg = (match, unmatch, errNo) => {
                                    return `${errNo} - in D.S.No ${current23['D.S.No']} & ${i['D.S.No']} - ${match} field match, but ${unmatch.join(', ')} field unmatched`
                                }
                                if (match?.length && unMatched?.length) {
                                    const errorRow = { ...current23 };
                                    const slicedName = nameSlice(errorRow[COLUMN_DETAILS.columnData.name.name])
                                    const errorRow2 = { ...i };
                                    const slicedName2 = nameSlice(errorRow2[COLUMN_DETAILS.columnData.name.name])
                                    const setErrMsg = (clumnName, name) => {
                                        const fndIndex = [...matchingNamesData[slicedName]].findIndex(i => i[COLUMN_DETAILS.columnData.SNo.name] === errorRow[COLUMN_DETAILS.columnData.SNo.name])
                                        const fndIndex2 = [...matchingNamesData[slicedName2]].findIndex(i => i[COLUMN_DETAILS.columnData.SNo.name] === errorRow2[COLUMN_DETAILS.columnData.SNo.name])
                                        const temp = matchingNamesData[slicedName][fndIndex][clumnName] || '';
                                        const temp2 = matchingNamesData[slicedName2][fndIndex2][clumnName] || '';
                                        unMatched.forEach(nm => {
                                            // {Amazon:{}}
                                            let value = changesCount[name][errorRow[name]];
                                            if (value) {
                                                // {Amazon:{phone:[]}}
                                                if (Array.isArray(value[nm])) {
                                                    if (!value[nm].includes(errorRow[nm]) && errorRow[nm]) value[nm].push(errorRow[nm])
                                                    if (!value[nm].includes(errorRow2[nm]) && errorRow2[nm]) value[nm].push(errorRow2[nm])
                                                } else value[nm] = [errorRow[nm], errorRow2[nm]].filter(Boolean);
                                                changesCount[name][errorRow[name]] = value
                                            } else {
                                                changesCount[name][errorRow[name]] = {
                                                    [nm]: [errorRow[nm], errorRow2[nm]].filter(Boolean)
                                                }
                                            }


                                            if (!temp?.includes(nm)) {
                                                let fieldName = nm;
                                                const fnI = changesCount[name][errorRow[name]][nm].findIndex(i => i === errorRow[nm]);
                                                // if value put index number
                                                if (fnI >= 0) fieldName = `TYPE_${fnI + 1}_${nm}`
                                                // if value is empty
                                                if (!errorRow[nm]) fieldName = `blank_${nm}`
                                                matchingNamesData[slicedName][fndIndex][clumnName] = `${temp ? temp + ',' : temp}${fieldName}`
                                            }
                                            // if (!temp2?.includes(nm)) matchingNamesData[slicedName2][fndIndex2][clumnName] = temp2.includes('#') ? temp2 + `#` : '#'
                                            if (!temp2?.includes(nm)) {
                                                let fieldName = nm;
                                                const fnI = changesCount[name][errorRow2[name]][nm].findIndex(i => i === errorRow2[nm]);
                                                // if value put index number
                                                if (fnI >= 0) fieldName = `TYPE_${fnI + 1}_${nm}`
                                                // if value is empty
                                                if (!errorRow2[nm]) fieldName = `blank_${nm}`
                                                matchingNamesData[slicedName2][fndIndex2][clumnName] = `${temp2 ? temp2 + ',' : temp2}${fieldName}`
                                            }
                                        })
                                    }
                                    // if two field match unmatched items available in two field's error
                                    // match.forEach(name => {
                                    //     setErrMsg(NAME_VALIDATION_COLUMN.matchedName(name), name)
                                    // })
                                    // if two field match unmatched items available in only first field's error
                                    setErrMsg(NAME_VALIDATION_COLUMN.matchedName(match[0]), match[0])

                                }
                            }
                        }

                        if ((tSplice === ti && current23['name']?.length !== i['name']?.length && tSplice?.length >= 3) || (current23['name']?.toLowerCase() === i['name']?.toLowerCase() && current23['name'] !== i['name'])) {
                            const errMsg = `15.5 - D.S.No - ${current23['D.S.No']} - "${current23['name']}" & D.S.No - ${i['D.S.No']} - "${i['name']}" - Vendor Name is partially similar. Please check`
                            errorNames.push(tSplice?.toLowerCase());
                            const fndIndex = matchingNamesData[tSplice]?.findIndex(i => i[COLUMN_DETAILS.columnData.SNo.name] === current23[COLUMN_DETAILS.columnData.SNo.name])
                            const fndIndex2 = matchingNamesData[tSplice]?.findIndex(j => j[COLUMN_DETAILS.columnData.SNo.name] === i[COLUMN_DETAILS.columnData.SNo.name])
                            matchingNamesData[tSplice][fndIndex][NAME_VALIDATION_COLUMN.partial] = `${i['D.S.No']}`
                            matchingNamesData[tSplice][fndIndex2][NAME_VALIDATION_COLUMN.partial] = `${current23['D.S.No']}`
                            // errorMessages.push({ dsNo: i['D.S.No'], msg: errMsg, field: ['name'] })
                            // errorMessages.push({ dsNo: current23['D.S.No'], msg: '', field: ['name'],higlight:true })

                            allowAllMsg && warning.push(constructErrMsg2({
                                name: 'name',
                                dsno: current23['D.S.No'],
                                sno: current23['S.No'],
                                // value: current23[x],
                                type: 'Warning',
                                msg: errMsg,
                                item: current23,
                            }))
                            console.log('trcgvhj');
                        }

                    }
                })
            }
        }
        // generalError.generalErrorCheck(item);
    })
    const filteredAmountSheet = amountMismatchArr.map((data, index) => {
        const filteredObj = Object.fromEntries(
            Object.entries(data).filter(([key]) => keysToKeepinAmountValidationSheet.includes(key) || key?.includes('__EMPTY'))
        );
        return filteredObj
    })
    //  name consistency not matching items name unique filter
    // only show error sets
    // const uniqueErrorNames = [...new Set(errorNames)];
    // if (uniqueErrorNames?.length) {
    //     uniqueErrorNames.forEach(i => {
    //         if (matchingNamesData[i]?.length) finalMatchingNamesData1.push(...matchingNamesData[i].reverse())

    //     })
    // }
    // show all sets
    Object.keys(matchingNamesData).map(name => {
        if (matchingNamesData[name]?.length) finalMatchingNamesData1.push(...matchingNamesData[name].reverse())
    })
    // get list of matching names object which have errors
    const finalMatchingNamesData = finalMatchingNamesData1.map((data, index) => {
        const filteredObj = Object.fromEntries(
            Object.entries(data).filter(([key]) => keysToKeepinNameValidationSheetAndColumn.includes(key))
        );
        return filteredObj
    }).sort((a, b) => a?.name > b?.name ? 1 : -1)
        .map(dat => {
            const t = {
                [keysToKeepinNameValidationSheetAndColumn[0]]: dat[keysToKeepinNameValidationSheetAndColumn[0]],
                [keysToKeepinNameValidationSheetAndColumn[1]]: dat[keysToKeepinNameValidationSheetAndColumn[1]],
                [keysToKeepinNameValidationSheetAndColumn[2]]: dat[keysToKeepinNameValidationSheetAndColumn[2]],
                [keysToKeepinNameValidationSheetAndColumn[3]]: dat[keysToKeepinNameValidationSheetAndColumn[3]],
                [keysToKeepinNameValidationSheetAndColumn[4]]: dat[keysToKeepinNameValidationSheetAndColumn[4]],
                [keysToKeepinNameValidationSheetAndColumn[5]]: dat[keysToKeepinNameValidationSheetAndColumn[5]],
                [keysToKeepinNameValidationSheetAndColumn[6]]: dat[keysToKeepinNameValidationSheetAndColumn[6]],
                [NAME_VALIDATION_COLUMN.name]: dat[NAME_VALIDATION_COLUMN.name] || '',
                [NAME_VALIDATION_COLUMN.email]: dat[NAME_VALIDATION_COLUMN.email] || '',
                [NAME_VALIDATION_COLUMN.phone]: dat[NAME_VALIDATION_COLUMN.phone] || '',
                [NAME_VALIDATION_COLUMN.taxId]: dat[NAME_VALIDATION_COLUMN.taxId] || '',
                [NAME_VALIDATION_COLUMN.partial]: dat[NAME_VALIDATION_COLUMN.partial] || '',
                [NAME_VALIDATION_COLUMN.emptyFields]: dat[NAME_VALIDATION_COLUMN.emptyFields] || '',
                checked: (dat[NAME_VALIDATION_COLUMN.name] || dat[NAME_VALIDATION_COLUMN.email] ||
                    dat[NAME_VALIDATION_COLUMN.phone] || dat[NAME_VALIDATION_COLUMN.taxId] || dat[NAME_VALIDATION_COLUMN.partial] || dat[NAME_VALIDATION_COLUMN.emptyFields]
                ) ? 'N' : ''
            }
            // const isFind = errorMessages?.find(msgData => msgData.dsNo === t[COLUMN_DETAILS.columnData.DSNo.name]);
            // if (isFind) {
            //     t['Error'] = isFind?.msg;
            //     // for testing
            //     // isFind?.field?.map(i => {
            //     //     if (isFind.higlight) t[i] = `<e1>${t[i] || ''}`
            //     //     else t[i] = `<e>${t[i] || ''}`
            //     // })
            //     // for testing
            // }
            return t
        })
    return { modifiedData, error, warning, filteredAmountSheet, finalMatchingNamesData, generalError: generalError.getErrList() }
}
const dateValidations = (item, addMsg, callBack) => {
    if (item['File Name']) {
        let issueDate = item['issuedDate'];
        let dueDate = item['dueDate'];
        const issueDateFormat = issueDate ? parseFormat(issueDate) : issueDate;
        const dueDateFormat = dueDate ? parseFormat(dueDate) : dueDate;
        console.log(issueDateFormat, 'issueDateFormatissueDateFormatissueDateFormat');
        if (!(moment(issueDate, 'MM/DD/YYYY', true).isValid() || moment(issueDate, 'YYYY-MM-DD', true).isValid()) && issueDate) {
            addMsg(constructErrMsg2({
                name: 'issueDate',
                dsno: item['D.S.No'],
                sno: item['S.No'],
                // changedValue: changedDate,
                value: issueDate,
                type: 'Warning',
                msg: '6.3 - Invalid date. Required keying format MM/DD/YYYY or YYYY-MM-DD ',
                // status: 'Changed',
                item
            }))
        } else if (issueDate && issueDateFormat !== 'YYYY-MM-DD') {
            const changedDate = moment(issueDate, issueDateFormat).format('YYYY-MM-DD');
            allowAllMsg && addMsg(constructErrMsg2({
                name: 'issueDate',
                dsno: item['D.S.No'],
                sno: item['S.No'],
                changedValue: changedDate,
                value: issueDate,
                type: 'Warning',
                msg: ERROR_MSG.dateFormat,
                status: 'Changed',
                item
            }))
            item['issuedDate'] = changedDate;
            issueDate = changedDate;
        }
        if (!(moment(dueDate, 'MM/DD/YYYY', true).isValid() || moment(dueDate, 'YYYY-MM-DD', true).isValid()) && dueDate) {
            addMsg(constructErrMsg2({
                name: 'dueDate',
                dsno: item['D.S.No'],
                sno: item['S.No'],
                // changedValue: changedDate,
                value: dueDate,
                type: 'Warning',
                msg: '7.3 - Invalid date',
                // status: 'Changed',
                item
            }))
        } else if (dueDate && dueDateFormat !== 'YYYY-MM-DD') {
            const changedDate = moment(dueDate, issueDateFormat).format('YYYY-MM-DD');
            allowAllMsg && addMsg(constructErrMsg2({
                name: 'dueDate',
                dsno: item['D.S.No'],
                sno: item['S.No'],
                value: dueDate,
                type: 'Warning',
                msg: ERROR_MSG.dateFormat,
                changedValue: changedDate,
                status: 'Changed',
                item
            }))
            item['dueDate'] = changedDate;
            dueDate = changedDate;
        }

        const validIssuDate = regExpConditions.dateFormatUsa.test(issueDate);
        const validDueDate = regExpConditions.dateFormatUsa.test(dueDate);
        // if (!validIssuDate) {
        //     addMsg(constructErrMsg2({
        //         name: 'issueDate',
        //         dsno: item['D.S.No'],
        //         sno: item['S.No'],
        //         value: issueDate,
        //         type: 'Warning',
        //         msg: ERROR_MSG.dateFormat,
        //     }))
        // }
        // if (!validDueDate) {
        //     addMsg(constructErrMsg2({
        //         name: 'dueDate',
        //         dsno: item['D.S.No'],
        //         sno: item['S.No'],
        //         value: dueDate,
        //         type: 'Warning',
        //         msg: ERROR_MSG.dateFormat,
        //     }))
        // }
        if (validIssuDate && validDueDate) {
            const date1 = moment(issueDate, 'YYYY-MM-DD');
            const date2 = moment(dueDate, 'YYYY-MM-DD');
            if (date1.isAfter(date2)) {
                addMsg(constructErrMsg2({
                    name: 'dueDate',
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    value: dueDate,
                    type: 'Warning',
                    msg: `6.1 - issueDate(${issueDate}) shouldn't be greater than dueDate(${dueDate})`,
                    item
                }))
            }
            if (date2.diff(date1, 'days') > 90) {
                addMsg(constructErrMsg2({
                    name: 'dueDate',
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    value: dueDate,
                    type: 'Warning',
                    msg: `7.5 - dueDate(${dueDate}) is greater more than 90 days from issueDate(${issueDate})`,
                    item
                }))
            }
        }
    }
    callBack();
}
const dateValidation = (data) => {
    if (!data) return true
    else if (regExpConditions.dateFormatUsa.test(data)) return true;
    else return false
}
const amountBasicValidation = async (item, name, addMsg, alternativeName, notchanged) => {
    const value = item[name]?.toString().replace(/\s/g, '');
    if ((Number(value) || Number(value) <= 0)) {
        const num = Number(value)?.toFixed(2);
        const t = (value?.toString().split('.')[1] || '')?.length;
        if (t > 2) {
            addMsg(constructErrMsg2({
                name: alternativeName || name,
                dsno: item['D.S.No'],
                sno: item['S.No'],
                value: value,
                type: 'Warning',
                msg: ERROR_MSG.amountDecimal,
                changedValue: notchanged ? '' : num,
                status: notchanged ? '' : 'Changed',
                item
            }))

        }
        !notchanged && (item[name] = num)
        return true
    } else return false
}
const amountAndQuantityValidation = (item, newItem, addMsg) => {
    const t = () => {
        const rmSpace = item['amount']?.toString().replace(/[\s,]/g, '')
        if (!item['amount']) return true
        else if ((Number(rmSpace) || Number(rmSpace) <= 0)) {
            if (rmSpace !== item['amount']?.toString()) {
                addMsg(constructErrMsg2({
                    name: 'amount',
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    value: item['amount'],
                    type: 'Error',
                    changedValue: (+rmSpace)?.toFixed(2),
                    msg: `8.3 - Space/Comma Removed from "amount" field and updated in "Data" Sheet. Please check`,
                    item,
                    status: 'Changed'
                }))

                newItem['amount'] = (+rmSpace)?.toFixed(2);
                amountBasicValidation(item, 'amount', addMsg)
            } else {
                newItem['amount'] = (+rmSpace)?.toFixed(2);
                amountBasicValidation(item, 'amount', addMsg)
            }
            return true
        }
        else {
            addMsg(constructErrMsg('amount', item['D.S.No'], item['S.No'], item['amount'], 'Warning', item));
        }
    }
    const t2 = () => {
        const rmSpace = item['amount_1']?.toString().replace(/[\s,]/g, '')
        if (!item['amount_1']) return true
        else if (Number(rmSpace) || Number(rmSpace) <= 0) {
            if (rmSpace !== item['amount_1']?.toString()) {
                allowAllMsg && addMsg(constructErrMsg2({
                    name: 'amount',
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    value: item['amount_1'],
                    type: 'Warning',
                    changedValue: (+rmSpace)?.toFixed(2),
                    msg: `12.4 - Space/Comma Removed from "amount" field and updated in "Data" Sheet. Please check`,
                    item,
                    status: 'Changed'
                }))

                newItem['amount_1'] = (+rmSpace)?.toFixed(2);
                amountBasicValidation(item, 'amount_1', addMsg);
            } else {
                newItem['amount_1'] = (+rmSpace)?.toFixed(2);
                amountBasicValidation(item, 'amount_1', addMsg);
            }
            return true
        }
        else addMsg(constructErrMsg('amount_1', item['D.S.No'], item['S.No'], item['amount_1'], 'Warning', item));
    }
    // const t3 = () => {
    //     if (item['Additional Fields'] === "quantity") {
    //         const quantityList = Object.keys(item);
    //         const quantityList2 = quantityList.map((i) => {
    //             if (i.includes('__EMPTY') && item[i]) {
    //                 if (Number(item[i]) >= 0) return newItem[i] = (+item[i])?.toFixed(2);
    //                 else addMsg(constructErrMsg2(
    //                     {
    //                         name: 'Additional Field quantity',
    //                         dsno: item['D.S.No'],
    //                         sno: item['S.No'],
    //                         value: item[i],
    //                         type: 'Warning',
    //                         msg: ERROR_MSG.additionalFieldQuantity
    //                     }
    //                 ));
    //             }
    //         })
    //     }
    // }
    t();
    t2();
    // t3();
}
const invoiceAndPoNumberCheck = (item, newItem, addMsg) => {
    const value = item['poNumber'];
    const newValue = value?.toString().replace(/[\s,.]+/g, '');
    const t = () => {
        // if (!item['invoiceNumber']) return true;
        // else if (regExpConditions.AlphaNumeric.test(item['invoiceNumber']) || regExpConditions.numeric.test(item['invoiceNumber'])) return true;
        // else addMsg(constructErrMsg2({
        //     name: 'invoiceNumber',
        //     dsno: item['D.S.No'],
        //     sno: item['S.No'],
        //     value: item['invoiceNumber'],
        //     type: 'Warning',
        //     msg: ERROR_MSG.invoiceNumber
        // }))
    }

    const t2 = () => {
        if (newValue && !regExpConditions.containNumber.test(newValue)) {
            addMsg(constructErrMsg2({
                name: 'poNumber',
                dsno: item['D.S.No'],
                sno: item['S.No'],
                value: item['poNumber'],
                type: 'Warning',
                msg: ERROR_MSG.poNumber,
                item,
                status: 'Changed'
            }))
            item['poNumber'] = null;
        }

    }
    item['File Name'] && t();
    item['File Name'] && t2();
}
function wordToTitleCase(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
const memoValidation = (value, item) => {
    try {
        if (!value) return true;
        else if (value === "Tax" || value === "Freight") return true;
        else if (value?.toLowerCase() === 'tax' || value?.toLowerCase() === 'freight') {
            const titCase = wordToTitleCase(value?.toLowerCase());
            item['memo'] = titCase;
            return true
        }
        else return false;
    } catch (error) {
        console.log(error, 'w3ertyguioppiouiuyt');
    }

}

const fileNameValidation = (value, value2) => {
    if (!value && !value2) return true;
    else if (value === value2) return true;
    else return false;
}

const nameValidation = (item, addMsg, value1, name1) => {
    // const value = value1?.replace(/,/g, " ");
    const value = value1;
    const splittedArr = value?.toString()?.split(' ');

    function wordToTitleCase(word) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    const name = name1?.includes('__EMPTY') ? `Additional Fields - ${item['Additional Fields']}` : name1;
    let finalName = '';
    // if (splittedArr?.length === 1 && value === value?.toUpperCase()) {
    //     const titCase = wordToTitleCase(value);
    //     item[name1] = titCase;
    //     addMsg(constructErrMsg2({
    //         name: name,
    //         dsno: item['D.S.No'],
    //         sno: item['S.No'],
    //         value: value1,
    //         type: 'Warning',
    //         msg: `"${name}" - changed to title case" - Please check`,
    //         item,
    //         changedValue: titCase,
    //         status: 'Changed',
    //     }))
    //     return
    // }
    for (let [index, word] of splittedArr?.entries()) {
        let isValid;
        let tempName = '';
        if (regExpConditions.isNotAlphaOnly.test(word)) isValid = true;
        if (word?.toUpperCase() === word) isValid = true;
        if (CONNECTING_WORDS.includes(word?.toLowerCase())) {
            if (word !== word?.toLowerCase() && index !== 0) {
                tempName = word?.toLowerCase();
                isValid = true;
            }
            if (index === 0) {
                tempName = wordToTitleCase(word)
                isValid = true;
            }
            if (word === word?.toLowerCase()) {
                isValid = true;

            }
            else if (index === 0) isValid = true;
        }
        else if (word === word?.toLowerCase()) {
            const isAlpha = regExpConditions.isFirstCharacterAlpha.test(word?.at(0));
            if (isAlpha && word?.length > 1) {
                const titCase = wordToTitleCase(word);
                tempName = titCase;
                isValid = true;
                allowAllMsg && addMsg(constructErrMsg2({
                    name: name,
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    // value: word,
                    value: value1,
                    type: 'Warning',
                    msg: `15.1 - in "${name}" - word "${word}" changed to "${titCase}" as title case - Please check`,
                    item,
                    changedValue: titCase,
                    status: 'Changed'
                }))
            }
        }
        else if (regExpConditions.titleCase.test(word)) isValid = true;
        const fn = tempName || word
        if (fn && fn != undefined) finalName += ` ${fn}`
        !isValid && addMsg(constructErrMsg2({
            name: name,
            dsno: item['D.S.No'],
            sno: item['S.No'],
            // value: word,
            value: value1,
            type: 'Warning',
            msg: `15.4 - "${name}" - Title Case/Connecting word error found in the word "${word}" - Please check`,
            item
        }))
    }
    item[name1] = finalName;

    // if (!value) return true;
    // else if (value === value.toUpperCase() || regExpConditions.titleCase.test(value)) return true;
    // else return false;
}

const emailValidation = (value, item, name) => {
    const val = value?.replace(/\s/g, '')?.replace(/[^\w\s]+$/, '')
    if (!val) return true;
    else if (regExpConditions.email.test(val)) {
        if (val !== value) item[name] = val
        return true;
    }
    else return false;
}

const phoneNoValidation = (item, addMsg) => {
    let value = item['phone'];
    try {
        const te = /-/g;
        if (value?.match(te)?.length === 2) {
            let splittedVal = item['phone']?.split('-')?.at(-1)?.length === 4
            if (!splittedVal && item['phone']?.split('-')?.length > 1) addMsg(constructErrMsg2({
                name: 'phone',
                dsno: item['D.S.No'],
                sno: item['S.No'],
                value: item['phone'],
                type: 'Error',
                changedValue: value,
                msg: `17.3 -  phone format may wrong.Kindly check`,
                item
            }))
        }

    } catch (error) {

    }
    if (regExpConditions.space.test(value)) {
        value = item['phone']?.replace(/\s/g, "");
        allowAllMsg && addMsg(constructErrMsg2({
            name: 'phone',
            dsno: item['D.S.No'],
            sno: item['S.No'],
            value: item['phone'],
            type: 'Error',
            changedValue: value,
            msg: `17.2 - Space Removed from "Phone" field and updated in "Data" Sheet. Please check`,
            item
        }))
        item['phone'] = value;

    }
    if (regExpConditions.phone.test(value)) return true;
    if (!value) return true;
    else return false;
}

const customerAccValidation = (item, newItem, addMsg) => {
    const value = item['invoiceNumber'];
    const value2 = item['customerAccount'];
    // if (!value && item['File Name'] && item['Comment'] !== cmt) {
    //     addMsg(constructErrMsg2({
    //         name: 'invoiceNumber',
    //         dsno: item['D.S.No'],
    //         sno: item['S.No'],
    //         value: value,
    //         type: 'Error',
    //         msg: ERROR_MSG.inVoiceMissing,
    //         item
    //     }))
    // }
    if (!value && value2) {
        addMsg(constructErrMsg2({
            name: 'customerAccount',
            dsno: item['D.S.No'],
            sno: item['S.No'],
            value: value2,
            type: 'Error',
            msg: ERROR_MSG.invoiceMissingButCustomerAccount,
            item
        }))
    }
}

const commentValidation = (value) => {
    if (!value) return true;
    else if (COMMENTS.includes(value)) return true;
    else return false;
}

const writeDates = async (item, newItem, addMsg) => {
    const id = item['issuedDate'];
    const dd = item['dueDate'];
    const terms = item['terms'];

    const addDueDate = (num, date) => {
        if (regExpConditions.dateFormatUsa.test(id) && +num >= 0 && (num || num === 0)) {
            console.log(+num, '+num+num+num');
            const t = moment(id, 'YYYY-MM-DD').add(+num, 'days').format('YYYY-MM-DD');
            if (!dd) {
                addMsg(constructErrMsg2({
                    name: 'dueDate',
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    value: dd,
                    type: 'Warning',
                    changedValue: t,
                    status: 'Changed',
                    msg: ERROR_MSG.dueDateValueAddedFromTerms,
                    item
                }))
                newItem['dueDate'] = t;
            }
            // else {
            //     if (dd !== t) {
            //         addMsg(constructErrMsg2({
            //             name: 'dueDate',
            //             dsno: item['D.S.No'],
            //             sno: item['S.No'],
            //             value: dd,
            //             type: 'Warning',
            //             changedValue: t,
            //             status: 'Changed',
            //             msg: `8.4 - Due date doesn't match with term field. Correct value is updated in data sheet. Please check`,
            //             item
            //         }))
            //         newItem['dueDate'] = t;
            //     }
            // }

        } else if (regExpConditions.dateFormatUsa.test(id) && date) {
            if (!dd) {
                addMsg(constructErrMsg2({
                    name: 'dueDate',
                    dsno: item['D.S.No'],
                    sno: item['S.No'],
                    value: dd,
                    type: 'Warning',
                    changedValue: date,
                    status: 'Changed',
                    msg: ERROR_MSG.dueDateValueAddedFromTerms,
                    item
                }))
                newItem['dueDate'] = date;
            }
            // else {
            //     if (dd !== date) {
            //         addMsg(constructErrMsg2({
            //             name: 'dueDate',
            //             dsno: item['D.S.No'],
            //             sno: item['S.No'],
            //             value: dd,
            //             type: 'Warning',
            //             changedValue: date,
            //             status: 'Changed',
            //             msg: `7.1 - Keyed duedate does't match with the terms field, correct value has been updated in data sheet.Please check`,
            //             item
            //         }))
            //         newItem['dueDate'] = date;
            //     }
            // }

        }
    }
    const numberConv = async (num) => {
        const match = num?.match(/\d+(?:st|nd|rd|th)/g);
        if (Number(num) >= 0) return num;
        else if (match?.length) {
            var num2 = match[0].replace(/(st|nd|rd|th)/g, "");
            if (Number(num2) >= 0) return num2;
        }
    }
    if (id && terms && !dd) {
        const splittedArr = terms?.split('Net');
        const match = terms?.match(/\d+(?:st|nd|rd|th)/g);
        if (match?.length && terms?.toLowerCase().includes('net')) {
            var num = match[0].replace(/(st|nd|rd|th)/g, "");
            if (terms?.toLowerCase().includes('eom')) {
                let startDate;
                if (Number(num) >= 0) startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').set('date', num).format('YYYY-MM-DD');
                else startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').format('YYYY-MM-DD');
                // var startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').endOf('month').format('YYYY-MM-DD');
                return addDueDate(null, startDate)
            }
            if (Number(num) >= 0) {
                var startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').set('date', num).format('YYYY-MM-DD');
                return addDueDate(null, startDate)
            }
        }
        else if (splittedArr?.length && terms?.includes('Net')) {
            const num = splittedArr.at(-1);
            const match = num?.match(/\d+(?:st|nd|rd|th)/g);
            const replacedStr = num.replace(/[A-Za-z\s]/g, '');
            // Net 10 EOM
            if (Number(replacedStr) > 0 && Number(replacedStr) < 32 && terms?.toLowerCase().includes('eom')) {
                // const startDate = moment(id, 'YYYY-MM-DD').add(+replacedStr, 'days').endOf('month').format('YYYY-MM-DD')
                const startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').set('date', replacedStr).format('YYYY-MM-DD');
                return addDueDate(null, startDate)
            }
            if (Number(num) >= 0 && terms?.toLowerCase().includes('eom')) {
                const replacedStr = num.replace(/[A-Za-z\s]/g, '');
                // Net 10 EOM doubt
                if (Number(replacedStr) > 0 && Number(replacedStr) < 32) {
                    const startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').set('date', +replacedStr).format('YYYY-MM-DD');
                    return addDueDate(null, startDate)
                }
                else {
                    const match = num?.match(/\d+(?:st|nd|rd|th)/g);
                    if (match?.length) {
                        const startDate = moment(id);
                        // calculate the end of the month using the endOf method
                        const endDate = startDate.clone().endOf('month');

                        // format the end date as a string in ISO format (YYYY-MM-DD)
                        const endDateString = endDate.format('YYYY-MM-DD');
                        return addDueDate(null, endDateString)
                    }
                }
            }
            if (Number(num) >= 0 && terms?.toLowerCase().includes('eom')) addDueDate(num);
            else if (match?.length) {
                var num2 = match[0].replace(/(st|nd|rd|th)/g, "");
                if (Number(num2) >= 0) return addDueDate(num2)
            }
            else if (num?.toLowerCase()?.replace(/\s/g, '') === "monthly") {
                var startDate = moment(id);

                // calculate the end of the month using the endOf method
                var endDate = startDate.clone().endOf('month');

                // format the end date as a string in ISO format (YYYY-MM-DD)
                var endDateString = endDate.format('YYYY-MM-DD');
                return addDueDate(null, endDateString)
            }
            else if (num?.toLowerCase()?.replace(/\s/g, '') === "cash") return addDueDate(0);
            else {
                const replacedStr = num.replace(/[A-Za-z\s]/g, '');
                if (Number(replacedStr) >= 0) return addDueDate(Number(replacedStr));

            }

        }
        // 21 MFI
        if (terms?.toLowerCase().includes('mfi')) {
            const replacedStr = terms?.replace(/[A-Za-z\s]/g, '');
            const num = await numberConv(replacedStr);
            if (Number(num) > 0 && Number(num) < 32) {
                var startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').set('date', +num).format('YYYY-MM-DD');
                return addDueDate(null, startDate)
            }

        }
        //  EOM+20, EOM+30, EOM 10
        // else if (terms?.toLowerCase().includes('eom') && terms?.toLowerCase()?.replace(/\s/g, "")?.split('eom')?.length === 2) {
        //     const splitted = terms?.toLowerCase()?.replace(/\s/g, "")?.split('eom')[1]
        //     if (Number(splitted)) {
        //         let startDate = moment(id);
        //         const endOfMonth = startDate.clone().endOf('month');
        //         const added = endOfMonth.add(Number(splitted), 'days').format('YYYY-MM-DD');
        //         return addDueDate(null, added)
        //     }
        // }
        // 30 Days EOM,30 Days from End Month,30th of Month Following
        else if (terms?.toLowerCase().includes('eom') || terms?.toLowerCase().includes('end month') || terms?.toLowerCase().includes('end of month following date of invoice')) {
            var startDate = moment(id);

            const startOfNextMonth = startDate.clone().add(1, 'month').startOf('month');

            // calculate the end of the month using the endOf method
            const endDate = startOfNextMonth.clone().endOf('month');
            // format the end date as a string in ISO format (YYYY-MM-DD)
            var endDateString = endDate.format('YYYY-MM-DD');
            return addDueDate(null, endDateString)
        }
        // 30th of Month Following, Prox 10th
        else if (terms?.toLowerCase().includes('of month following') || terms?.toLowerCase().includes('prox')) {
            const replacedStr = Number(terms?.replace(/[A-Za-z\s]/g, ''));
            if (replacedStr > 0 && replacedStr < 32) {
                var startDate = moment(id, 'YYYY-MM-DD').add(1, 'M').set('date', +replacedStr).format('YYYY-MM-DD');
                return addDueDate(null, startDate)
            }

        }
        // COD, Due Upon Completion
        else if ((terms?.toLowerCase() === 'cod' || terms?.toLowerCase().toLowerCase() === "due upon completion") && !item['dueDate']) {
            return addMsg(constructErrMsg2({
                name: 'dueDate',
                dsno: item['D.S.No'],
                sno: item['S.No'],
                value: dd,
                type: 'Warning',
                msg: '7.4 - Due Date is empty.Kindly refer terms',
                item
            }))
        }
        // Due in 30 Days, 30 days
        else if ((terms?.toLowerCase().includes('due in') && terms?.toLowerCase().includes('days')) || terms?.toLowerCase().replace(/[0-9\s]/g, '') === "days") {
            const replacedStr = terms?.replace(/[A-Za-z\s]/g, '');
            const res = await numberConv(replacedStr);
            if (res || res === 0) return addDueDate(res);
        }
        // Due the 15th of the Month
        else if (terms?.toLowerCase().includes('due the') && terms?.toLowerCase().includes('the month')) {
            const replacedStr = terms?.replace(/[A-Za-z\s]/g, '');
            const res = await numberConv(replacedStr);
            if (res || res === 0) {
                const startDate = moment(id, 'YYYY-MM-DD').set('date', +res).format('YYYY-MM-DD');
                return addDueDate(null, startDate)
            }
        }
        // Due Upon Receipt, Immediate
        else if (terms?.toLowerCase().includes('due upon receipt') || terms?.toLowerCase() === "immediate" || terms?.toLowerCase() === "due upon presentation") return addDueDate(0);

        // Last Day of Month, 30 days from end of current/this month
        else if (terms?.toLowerCase() === "last day of month" || terms?.toLowerCase().includes("days from end of current/this month") || terms?.toLowerCase().includes('end of this calendar month')) {
            var startDate = moment(id);
            // calculate the end of the month using the endOf method
            const endDate = startDate.clone().endOf('month');
            // format the end date as a string in ISO format (YYYY-MM-DD)
            var endDateString = endDate.format('YYYY-MM-DD');
            return addDueDate(null, endDateString)
        }
        else if (terms?.toLowerCase().replace(/[0-9\s]/g, '') === "days") {
            const replacedStr = terms?.replace(/[A-Za-z\s]/g, '');
            const res = await numberConv(replacedStr);
            if (res || res === 0) return addDueDate(res);
        }
    }
}

const checkTotalAmount = (item, newItem, addMsg, data, index, addAmountMismatch) => {

    const amountList = Object.keys(item);
    if (item['Additional Fields'] === "amount") {
        try {

            let amountCheck;
            const amountList2 = amountList.map((i, position) => {
                if (i.includes('__EMPTY') && item[i]) {
                    let rmSpace = item[i]?.toString().replace(/[\s]/g, '')
                    const te = /\./g
                    if (rmSpace.match(te)?.length > 1) {
                        rmSpace = rmSpace.replace(/\./, '');
                        addMsg(constructErrMsg2({
                            name: 'Aditional Field -  amount',
                            dsno: item['D.S.No'],
                            sno: item['S.No'],
                            value: item[i],
                            type: 'Warning',
                            changedValue: (+rmSpace)?.toFixed(2),
                            msg: `23.4 - Additional Field - "amount" removed one period(.) from keyed value.Kindly check.`,
                            item,
                            status: 'Changed'
                        }))
                    }
                    if ((Number(rmSpace) || Number(rmSpace) <= 0)) {
                        if (rmSpace !== item[i]?.toString()) {
                            allowAllMsg && addMsg(constructErrMsg2({
                                name: 'Aditional Field -  amount',
                                dsno: item['D.S.No'],
                                sno: item['S.No'],
                                value: item[i],
                                type: 'Warning',
                                changedValue: (+rmSpace)?.toFixed(2),
                                msg: `23.1 - Space/Comma Removed from "Aditional Field -  amount" field and updated in "Data" Sheet. Please check`,
                                item,
                                status: 'Changed'
                            }))

                            data[index][i] = (+rmSpace)?.toFixed(2);
                        } else data[index][i] = (+item[i])?.toFixed(2)
                        amountBasicValidation(item, i, addMsg, 'Additional Filed amount', true)
                        return +item[i];


                    }
                    else {
                        console.log(item[i], 'item,i,item,i,');
                        addMsg(constructErrMsg2({
                            name: 'Aditional Field -  amount',
                            dsno: item['D.S.No'],
                            sno: item['S.No'],
                            value: item[i],
                            type: 'Error',
                            msg: ERROR_MSG.additionalFieldAmtInvalid,
                            item

                        }))
                    }

                    if (amountCheck && amountCheck + 1 !== position) {
                        addMsg(constructErrMsg2({
                            name: 'Additional Field - memo',
                            dsno: item['D.S.No'],
                            sno: item['S.No'],
                            value: '',
                            type: 'Error',
                            msg: `26.1 - Additional Field - memo column may wrongly entered. Please check.`,
                            item
                        }))
                    }
                    amountCheck = position;
                }
                else return 0
            })
            // if total amount field exceed 99 count comment should be
            const getEnteredAmount = amountList2?.filter(Boolean)
            if (getEnteredAmount?.length > 99) {
                const enteredCmt = item['Comment'];
                if (enteredCmt !== totalExceedCmt) {
                    addMsg(constructErrMsg2({
                        name: 'Comment',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: item['Comment'],
                        type: 'Error',
                        msg: `20.4 - AdditionalField - amount count is more than 99,So comment updated as "${totalExceedCmt}" in data sheet.Kindly check.`,
                        item,
                        status: 'Changed',
                        changedValue: totalExceedCmt
                    }))
                    data[index]['Comment'] = totalExceedCmt
                }
            }
            //  else if (item['Comment'] === totalExceedCmt && getEnteredAmount?.length < 99) {
            //     addMsg(constructErrMsg2({
            //         name: 'Comment',
            //         dsno: item['D.S.No'],
            //         sno: item['S.No'],
            //         value: item['Comment'],
            //         type: 'Error',
            //         msg: `20.5 - AdditionalField - amount count should be more than 99 for the comment keyed as "${totalExceedCmt}".So, comment has been removed in datasheet.Kindly check.`,
            //         item,
            //         status: 'Changed',
            //         changedValue: totalExceedCmt
            //     }))
            //     item['Comment'] = ""
            // }
            // if total amount field exceed 99 count comment should be

            const sum = amountList2.reduce((accumulator, currentValue) => +accumulator + +currentValue);
            const am = +item['amount_1'] || 0;
            const am2 = data?.length > index + 1 ? +data[index + 1]['amount_1'] || 0 : 0
            const invoiceAmt = item['amount'] ? (Number(item['amount']) >= 0 || Number(item['amount']) <= 0) ? (+item['amount'])?.toFixed(2) : item['amount'] : "0.00";
            const total = (+sum + +am + +am2)?.toFixed(2);
            const folderName = (item['TYPE'])?.includes('b');
            if (folderName && ADDITIONAL_FIELDS.includes(item['Additional Fields'])) {
                const fields = amountList.map((i) => {
                    if (i.includes('__EMPTY') && item[i]) {
                        const name = i.includes('__EMPTY') ? item['Additional Fields'] : i
                        addMsg(constructErrMsg2({
                            name: name,
                            dsno: item['D.S.No'],
                            sno: item['S.No'],
                            value: item[i],
                            type: 'Error',
                            msg: `27.7 - ${name} - have value. but b - folder files shouldn't contain values`,
                            item
                        }))
                    }

                })
            }
            if (!folderName) {
                console.log(invoiceAmt, total, item['amount'], 'invoiceAmt !== total');
                if ((invoiceAmt !== total) && !folderName) {
                    addAmountMismatch(data[index]);
                    addAmountMismatch(data[index + 1]);
                    addAmountMismatch(data[index + 2]);
                    addAmountMismatch(data[index + 3]);
                    addMsg(constructErrMsg2({
                        name: 'amount',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: item['amount'],
                        type: 'Error',
                        msg: `8.2 - Mismatch Found - Actual Invoice Amt  -------> "${invoiceAmt}" Keyed Total Amt (Ad. Amt  + Tax Amt)  -------> "${total === "NaN" ? 'One of the keyed value is not a valid number' : total}"`,
                        item
                    }))
                }
                let memoCheck;
                const memo = amountList.map((i, position) => {
                    const value = data[index + 1][i];
                    if (i.includes('__EMPTY') && data[index + 1][i]) {
                        if (memoCheck && memoCheck + 1 !== position) {
                            addMsg(constructErrMsg2({
                                name: 'Additional Field - memo',
                                dsno: item['D.S.No'],
                                sno: data[index + 1]['S.No'],
                                value: '',
                                type: 'Error',
                                msg: `26.1 - Additional Field - memo column may wrongly entered. Please check.`,
                                item
                            }))
                        }
                        memoCheck = position;
                        return value;
                    }
                })
                let qtCheck;
                const qty = Object.keys(data[index + 2]).map((i, position) => {
                    const value = data[index + 2][i];
                    if (i.includes('__EMPTY') && data[index + 2][i]) {
                        if (qtCheck && qtCheck + 1 !== position) {
                            addMsg(constructErrMsg2({
                                name: 'Additional Field - quantity',
                                dsno: item['D.S.No'],
                                sno: data[index + 2]['S.No'],
                                value: '',
                                type: 'Error',
                                msg: `24.2 - Additional Field - quantity column may wrongly entered. Please check.`,
                                item
                            }))
                        }
                        qtCheck = position;
                        return value;
                    }
                })
                let costCheck;
                const cost = Object.keys(data[index + 3]).map((i, position) => {
                    const value = data[index + 3][i];
                    if (i.includes('__EMPTY') && data[index + 3][i]) {
                        if (costCheck && costCheck + 1 !== position) {
                            addMsg(constructErrMsg2({
                                name: 'Additional Field - Cost',
                                dsno: item['D.S.No'],
                                sno: data[index + 3]['S.No'],
                                value: '',
                                type: 'Error',
                                msg: `25.2 - Additional Field - Cost column may wrongly entered. Please check.`,
                                item
                            }))
                        }
                        costCheck = position;
                        return value;
                    }
                })
                const t1 = amountList2.filter(Boolean);
                const t2 = memo.filter(Boolean);
                const t3 = qty.filter(Boolean);
                const t4 = cost.filter(Boolean);
                if (t1.length !== t2.length) {
                    addMsg(constructErrMsg2({
                        name: 'amount && memo field',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: '',
                        type: 'Error',
                        msg: ERROR_MSG.AdditionalFieldAmountAndMemoCount,
                        item
                    }))
                }
                if (t3.length > t1.length) {
                    addMsg(constructErrMsg2({
                        name: 'Additional Field - quantity',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: '',
                        type: 'Error',
                        msg: `24.3 - Additional Field - quantity column count exceeded then amount column. Please check.`,
                        item
                    }))
                }
                if (t4.length > t1.length) {
                    addMsg(constructErrMsg2({
                        name: 'Additional Field - Cost',
                        dsno: item['D.S.No'],
                        sno: item['S.No'],
                        value: '',
                        type: 'Error',
                        msg: `25.3 - Additional Field - Cost column count exceeded then amount column. Please check.`,
                        item
                    }))
                }
            }

        } catch (error) {

        }

    }

}

